
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonSpinner,
    IonIcon,
    IonButton,
    IonButtons,
    IonInput,
    IonTextarea,
    IonSelect,
    IonSelectOption,
    onIonViewWillEnter,
    menuController,
    modalController,
} from "@ionic/vue";
import { refresh, menu, arrowBack, arrowForward } from "ionicons/icons";

import { computed, onBeforeUnmount, onMounted, reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";

//Lettura QR
import { QrStream } from "vue3-qr-reader";

import moment from "moment";

import { openToast } from "@/services/toast";

import apiPresenze from "@/services/presenze";
import apiReparti from "@/services/reparti";
import apiLogin from "@/services/login";

import Reperibilita from "@/components/Reperibilita.vue";
import ModalCreaPresenza from "@/components/ModalCreaPresenza.vue";

export default {
    name: "Presenze",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonSpinner,
        IonIcon,
        IonButton,
        IonButtons,
        IonInput,
        IonSelect,
        IonSelectOption,
        IonTextarea,
        QrStream,
    },
    setup() {
        const router = useRouter();
        const loading = ref(false);
        const richieste = ref([]);

        const userID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_user_id;
        const dipendenteID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_id;
        const user = JSON.parse(localStorage.getItem("userInfo"));
        //Get dipendente settings
        /* const {
            dipendenti_consenti_straordinari,
            dipendenti_dichiara_reparto,
            dipendenti_reperibilita,
            dipendenti_timbra_qrcode,
            dipendenti_geolocalizzazione,
            dipendenti_timbra_posteriori,
        } = user; */

        //Nuovi campi per timbratura
        const presenze = ref([]);
        const entrataTimbrata = ref(false);
        const uscitaTimbrata = ref(false); //opposto del timbra entrata

        const statusShowItem = ref({});
        //Real time clock
        const timer = ref(null);
        const time = ref(null);

        const mustDeclareGeolocation = ref(false);
        const rejectedGeolocation = ref(false);

        /**
         * Get current geo coordinates
         */
        const position = ref(null);
        function onSuccess(pos) {
            const crd = pos.coords;
            position.value = pos.coords;

            console.log("Your current position is:");
            console.log(`Latitude : ${crd.latitude}`);
            console.log(`Longitude: ${crd.longitude}`);
            console.log(`More or less ${crd.accuracy} meters.`);
        }
        function onError(err) {
            //1: PERMISSION_DENIED
            if (err.code === 1) {
                rejectedGeolocation.value = true;
            } else {
                //2: POSITION_UNAVAILABLE, 3: TIMEOUT
                rejectedGeolocation.value = false;
            }
            console.warn(`ERROR(${err.code}): ${err.message}`);
        }
        /* async function getLocation() {
            navigator.geolocation.getCurrentPosition(onSuccess, onError, geoOptions);
        } */

        const geoOptions = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
        };
        async function watchGetLocation() {
            navigator.geolocation.watchPosition(onSuccess, onError, geoOptions);
        }

        //getLocation();
        //watchGetLocation();

        /**
         * ! Salva dati del dipendente
         */
        const dipendenteSettings = ref({
            dipendenti_timbra_entrata_uscita: null,
            dipendenti_consenti_straordinari: null,
            dipendenti_dichiara_reparto: null,
            dipendenti_reperibilita: null,
            dipendenti_timbra_qrcode: null,
            dipendenti_geolocalizzazione: null,
            dipendenti_timbra_posteriori: null,
            dipendenti_nascondi_pulsanti_timbrature: null,
        });

        async function getDipendenteData() {
            const res = await apiLogin.getUserDipendente(userID);
            //console.log(res);
            if (res.status === 200 && res.data.status === 0) {
                const {
                    dipendenti_timbra_entrata_uscita,
                    dipendenti_consenti_straordinari,
                    dipendenti_dichiara_reparto,
                    dipendenti_reperibilita,
                    dipendenti_timbra_qrcode,
                    dipendenti_geolocalizzazione,
                    dipendenti_timbra_posteriori,
                    dipendenti_nascondi_pulsanti_timbrature,
                } = res.data.data[0];
                dipendenteSettings.value = {
                    dipendenti_timbra_entrata_uscita,
                    dipendenti_consenti_straordinari,
                    dipendenti_dichiara_reparto,
                    dipendenti_reperibilita,
                    dipendenti_timbra_qrcode,
                    dipendenti_geolocalizzazione,
                    dipendenti_timbra_posteriori,
                    dipendenti_nascondi_pulsanti_timbrature,
                };
                //console.log(dipendenteSettings.value);
            }

            if (dipendenteSettings.value.dipendenti_geolocalizzazione === "1") {
                mustDeclareGeolocation.value = true;
                await watchGetLocation();
            }
        }

        /**
         * ! Menu handler
         */
        const openMenu = () => {
            menuController.open("app-menu");
        };

        // update the time every second
        function interval() {
            setInterval(() => {
                time.value = Intl.DateTimeFormat(navigator.language, {
                    hour: "numeric",
                    minute: "numeric",
                }).format();
            }, 1000);
        }

        interval();

        const options = { day: "2-digit", month: "long", year: "numeric" };
        const todayDate = new Date().toLocaleString("it-IT", options);

        /**
         * ! Apre modale per creare presenza completa con data ed ora inizio e fine + selezione cliente
         */
        async function createPresenza() {
            const modal = await modalController.create({
                component: ModalCreaPresenza,
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    //console.log(detail.data);
                    presenze.value = [detail.data, ...presenze.value];
                    openToast("Presenza inserita correttamente", "toast_success");
                }
            });
            return modal.present();
        }

        /**
         * ! Gestione stampa cliente o meno se associato alla presenza
         */
        function printCustomer(timbratura) {
            if (timbratura.customers_company) {
                const trimmedString =
                    timbratura.customers_company.length > 35 ? timbratura.customers_company.substring(0, 32) + "..." : timbratura.customers_company;
                return ` - ${trimmedString}`;
            } else if (timbratura.customers_name && timbratura.customers_last_name) {
                const reference = `${timbratura.customers_name} ${timbratura.customers_last_name}`;
                const trimmedString = reference.length > 35 ? reference.substring(0, 32) + "..." : reference;
                return ` - ${trimmedString}`;
            }
        }

        /**
         * ! Gestione straordinario manuale e richiesta dati reparti
         */
        const reparti = ref([]);
        const repartoSelezionato = ref(null);
        async function loadReparti() {
            apiReparti
                .getUserReparti(dipendenteID)
                .then((response) => {
                    if (response.data.length != 0) {
                        reparti.value = response.data;
                    } else {
                        reparti.value = [];
                    }
                })
                .catch((error) => {
                    console.error(error);
                    openToast("Errore durante la richiesta dei reparti", "danger");
                });
        }
        loadReparti();

        /**
         * Setta il reparto, obbligatorio prima di inserire la presenza
         * @param reparto_id string of selected reparto
         */
        function setReparto(reparto_id) {
            repartoSelezionato.value = reparto_id;
        }

        const showStraordinario = ref(false);
        const selectedPresenza = ref(null);

        function apriStraordinario(presenza) {
            if (presenza.presenze_data_fine || presenza.presenze_data_fine == "") {
                selectedPresenza.value = { ...presenza };
                showStraordinario.value = true;
            } else {
                openToast("Potrai inserire lo staordinario solamente dopo aver chiuso la presenza", "toast_danger");
            }
        }
        function chiudiStraordinario() {
            selectedPresenza.value = null;
            showStraordinario.value = false;
        }

        /**
         * ! EDIT PRESENZA
         */

        function editPresenza() {
            //se devo modificare straordinario faccio controllo che sia positivo
            if (
                dipendenteSettings.value.dipendenti_consenti_straordinari === "1" &&
                selectedPresenza.value &&
                (selectedPresenza.value.presenze_straordinario < 0 || selectedPresenza.value.presenze_straordinario === "")
            ) {
                openToast("Devi inserire un valore maggiore o uguale a 0 per salvare lo straordinario", "toast_danger");
                return;
            } else {
                const presenzaId = selectedPresenza.value.presenze_id;
                const straordinario = selectedPresenza.value.presenze_straordinario;
                const note = selectedPresenza.value.presenze_note ? selectedPresenza.value.presenze_note : "";

                apiPresenze
                    .editPresenza(presenzaId, straordinario, note)
                    .then((response) => {
                        //console.log(response);
                        if (response.data.status === 0) {
                            const res = response.data.data[0];
                            //Aggiorno presenza appena modificata e chiudo la modale
                            const presenzaModificata = presenze.value.find((element) => element.presenze_id === res.presenze_id);
                            if (presenzaModificata) {
                                presenzaModificata.presenze_straordinario = res.presenze_straordinario;
                                presenzaModificata.presenze_note = res.presenze_note;
                                openToast("Presenza modificata correttamente", "toast_success");
                                showStraordinario.value = false;
                            }
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        openToast("Errore durante la modifica della presenza", "toast_danger");
                    });
            }
        }

        /**
         * ! DELETE SELECTED PRESENZA
         */
        async function deletePresenza(presenza_id) {
            try {
                const response = await apiPresenze.deletePresenza(presenza_id, userID);
                console.log(response);
                if (response.status === 200 && response.data.status === 8) {
                    openToast(response.data.message, "toast_danger");
                } else if (response.status === 200 && response.data.status === 0) {
                    presenze.value = presenze.value.filter((presenza) => presenza.presenza_id != presenza_id);
                    selectedPresenza.value = null;
                    showStraordinario.value = false;
                    openToast("Presenza cancellata correttamente", "toast_success");
                } else {
                    openToast("Errore durante la cancellazione della presenza", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la cancellazione della presenza", "toast_danger");
            }
        }

        /**
         * ! Apre pagiuna per inserire la reperibilità e visualizzare quelle inserite in elenco,
         * ! Se comunicata nell'ultima ora mostro button per poterla eliminare
         */
        async function openReperibilita() {
            const modal = await modalController.create({
                component: Reperibilita,
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    //richieste.value = [detail.data, ...richieste.value];
                    openToast("Reperibilita comeunicata correttamente", "toast_success");
                }
            });
            return modal.present();
        }

        /**
         * Imposta lo straordinario sulla singola presenza
         * @param presenzaId id presenza su cui modificare straordinario
         * @param ore_straoardinario ore di straoarindario effettuate
         */
        /*     function setStraordinario(presenzaId, ore_straoardinario) {
      if (ore_straoardinario != 0) {
        apiPresenze
          .saveStraordinario(presenzaId, ore_straoardinario)
          .then((response) => {
            //console.log(response);
            if (response.data.status === 0) {
              const res = response.data.data[0];
              //Cerco la presenza appena modificata e la aggiorno
              const presenzaModificata = presenze.value.find((element) => element.presenze_id === res.presenze_id);
              if (presenzaModificata) {
                presenzaModificata.presenze_straordinario = res.presenze_straordinario;

                openToast("Straordinario inserito correttamente", "toast_success");
              }
            }
          })
          .catch((error) => {
            console.error(error);
            openToast("Errore durante l'inserimento dello straordinario", "toast_danger");
          });
      } else {
        openToast("Devi inserire un valore maggiore di 0 per salvare lo straordinario", "toast_danger");
      }
    } */

        //2) CONTROLLO QUALI PULSANTI DISABILITARE
        /**
         * Se ho timbratura di oggi senza presenze_ora_fine vuol dire che ho timbrato SOLO l'entrata -> disabilito entrata
         * Se ho timbratura di oggi ed ha anche presenze_ora_fine vuol dire che ho timbrato entrati -> riabilito entrata per succissivo utilizzo
         */
        function checkTimbrature() {
            const presenzeOggi = presenze.value.some(
                (presenza) =>
                    moment(presenza.presenze_data_inizio).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") &&
                    (presenza.presenze_ora_fine === null || presenza.presenze_ora_fine === "")
            );
            if (presenzeOggi) {
                entrataTimbrata.value = true;
            } else {
                entrataTimbrata.value = false;
            }
            /*const today = moment().format("YYYY-MM-DD");
            const ultimaTimbratura = presenze.value[0];
            const entrata = moment(ultimaTimbratura.presenze_data_inizio).format("YYYY-MM-DD");
            //mi prendo l'ultima timbratura effettuata e se NON ha end time allora ho fatto solo entrata quindi disabilito entrata
            //altrimenti riabilito entrata perchè le ho già fatte entrambe (potrebbe essere stata chiusa dal cron e non da me)
            if ((entrata === today && ultimaTimbratura.presenze_ora_fine === null) || ultimaTimbratura.presenze_ora_fine === "") {
                entrataTimbrata.value = true;
            } else {
                entrataTimbrata.value = false;
            }*/
        }

        //1) SCARICO TIMBRATURE EFFETTUATE e controllo se disabilitare entrata
        async function loadTimbrature() {
            loading.value = true;
            apiPresenze
                .getTimbrature(dipendenteID)
                .then((response) => {
                    //console.log("TIMBRATURE EFFETTUATE: ", response);
                    presenze.value = response;
                    if (response.length > 0) {
                        checkTimbrature();
                    } else {
                        entrataTimbrata.value = false;
                        uscitaTimbrata.value = false;
                    }
                    //checkTimbrature(response);
                })
                .catch((error) => {
                    console.error(error);
                    openToast("Errore durante la richiesta delle presenze", "danger");
                })
                .finally(() => {
                    statusShowItem.value = {};
                    loading.value = false;
                });
        }

        /**
         * ! CONTROLLO presenze
         * * Se trovo almeno una presenza di oggi senza data fine vuol dire che sto timbrando l'uscita (posso averne fatte altre oggi ma sempre entrando ed uscendo)
         * * Se non la trovo vuol dire che sto timbrando l'entrata (o è la prima/unica della giornata o sto entrando nuovamente)
         */
        const confirmationText = ref("");
        function checkPresenze() {
            const presenzeOggi = presenze.value.some(
                (presenza) =>
                    moment(presenza.presenze_data_inizio).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") &&
                    (presenza.presenze_ora_fine === null || presenza.presenze_ora_fine === "")
            );
            const orario = moment().format("HH:mm");

            if (presenzeOggi) {
                confirmationText.value = `Cliccando su "Salva" confermi di timbrare l'uscita alle ore ${orario}`;
            } else {
                confirmationText.value = `Cliccando su "Salva" confermi di timbrare l'entrata alle ore ${orario}`;
            }
        }

        /**
         * QR Code handler
         */
        const state = reactive({
            data: null,
        });
        const showQrCode = ref(false);
        const disabledQrCodeButton = ref(false);

        function onDecode(data) {
            state.data = data;
            if (data) {
                //Stampo informazioni per avvisare il dipendente della timbratura che sta per effettuare (entrata o uscita)
                //console.log(data);
                //checkTimbrature();
                checkPresenze();
            }
        }

        function openQrCode() {
            showQrCode.value = true;
        }
        function chiudiQrCode() {
            showQrCode.value = false;
            state.data = null;
            confirmationText.value = "";
            disabledQrCodeButton.value = false;
        }
        function handleQrCodeTimbratura() {
            console.log(state.data);
            if (state.data) {
                let qrValue = state.data.slice(-5);
                disabledQrCodeButton.value = true;

                apiPresenze
                    .scanQrCode(qrValue, dipendenteID)
                    .then((response) => {
                        if (response.data.status === 0) {
                            //error
                            openToast(response.data.txt, "toast_danger");
                        } else {
                            if (response.data.tipo === "entrata") {
                                //aggiungo nuova presenza
                                presenze.value = [response.data.data, ...presenze.value];
                                entrataTimbrata.value = true;
                            } else {
                                //devo modificare presenza e chiudo la modale
                                const presenzaModificata = presenze.value.find((element) => element.presenze_id === response.data.data.presenze_id);
                                if (presenzaModificata) {
                                    presenzaModificata.presenze_data_fine = response.data.data.presenze_data_fine;
                                    presenzaModificata.presenze_ora_fine = response.data.data.presenze_ora_fine;
                                    presenzaModificata.presenze_straordinario = response.data.data.presenze_straordinario;
                                }
                                entrataTimbrata.value = false;
                            }
                            openToast(response.data.txt, "toast_success");
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        openToast("Errore durante la scansione del codice QR", "toast_danger");
                    })
                    .finally(() => {
                        state.data = null;
                        showQrCode.value = false;
                        qrValue = null;
                        confirmationText.value = "";
                        disabledQrCodeButton.value = false;
                    });
            } else {
                openToast("Inquadra il codice QR prima di salvare la presenza", "toast_danger");
            }
        }

        /**********************************************************************************************************************/
        //5) UTILS
        /**
         * Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY
         */
        function dateFormat(date) {
            if (date && moment.isDate(new Date(date))) {
                return moment(date).format("DD/MM/YYYY");
            } else {
                return "-";
            }
        }
        /**
         * Format date from YYYY/MM/DD HH:mm:ss to HH:mm
         */
        function timeFormat(date) {
            if (date && moment.isDate(new Date(date))) {
                return moment(date).format("HH:mm");
            } else {
                return "-";
            }
        }

        //Set correct background for richieste status
        const statoReperibilita = computed(() => {
            return (statusId) => {
                let className = "";
                if (statusId == 0) {
                    //attesa approvazione
                    className = "badge_danger";
                } else if (statusId == 1) {
                    //approvato
                    className = "badge_success";
                }
                return className;
            };
        });

        /**********************************************************************************************************************/
        //4A) TIMBRATURA USCITA - Edit della presenza di oggi e senza presenze_ora_fine
        function timbraUscita(uscita_data, uscita_ora) {
            const ora_uscita = uscita_ora;
            const data_uscita = uscita_data;
            const data = new FormData();
            data.append("presenze_dipendente", dipendenteID);
            data.append("presenze_data_inizio", data_uscita);
            data.append("presenze_ora_fine", ora_uscita);

            apiPresenze
                .chiudiPresenza(dipendenteID, ora_uscita, "APP")
                .then((response) => {
                    console.log(response);
                    if (response.data.status === 0) {
                        openToast(response.data.txt, "toast_danger");
                    } else if (response.data.status === 1) {
                        openToast(response.data.txt, "toast_success");
                        //Cerco la presenza appena modificata, la aggiorno e abilito di nuovo btn entrata
                        const presenzaDaChiudure = presenze.value.find((element) => element.presenze_id === response.data.uscita.presenze_id);
                        const presenzaChiusa = response.data.uscita;
                        if (presenzaDaChiudure) {
                            //console.log(presenzaDaChiudure);
                            presenzaDaChiudure.presenze_data_fine = presenzaChiusa.presenze_data_fine;
                            presenzaDaChiudure.presenze_ora_fine = presenzaChiusa.presenze_ora_fine;
                            presenzaDaChiudure.presenze_straordinario = parseFloat(presenzaChiusa.presenze_straordinario).toFixed(2);

                            entrataTimbrata.value = false;
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                    openToast("Errore durante la timbratura", "toast_danger");
                });
        }
        //4) SALVO PRESENZA USCITA
        /**
         * Se ho effettuato una timbratura in entrata (la presenza SENZA ora_fine è quella di oggi)
         * allora posso fare una timbratura in uscita --> edit presenze impostando ora e data fine
         * altrimenti mostro errore
         */
        function handleTimbraUscita() {
            const data_uscita = moment().format("YYYY-MM-DD");
            const ora_uscita = moment().format("HH:mm");
            timbraUscita(data_uscita, ora_uscita);
        }

        /* ===================================================================================================================== */
        //3A) SALVA PRESENZA IN ENTRATA
        function timbraEntrata(entrata_data, entrata_ora, reparto) {
            const ora_entrata = entrata_ora;
            const data_entrata = entrata_data;

            apiPresenze
                .timbraEntrata(dipendenteID, ora_entrata, reparto, "APP")
                .then((response) => {
                    //console.log(response);
                    if (response.data.status === 0) {
                        openToast(response.data.txt, "toast_danger");
                    } else if (response.data.status === 1) {
                        openToast(response.data.txt, "toast_success");
                        presenze.value = [response.data.entrata, ...presenze.value];
                        entrataTimbrata.value = true;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    openToast("Errore durante la timbratura", "toast_danger");
                });
        }

        //3) GESTISCE PRESENZA IN ENTRATA ( --------> [HR - APP] - Presenza in entrata ed orari calendario )
        function handleTimbraEntrata() {
            //Se devo comunicare prima il reparto controllo di averlo selezionato
            if (dipendenteSettings.value.dipendenti_dichiara_reparto === "1" && !repartoSelezionato.value) {
                openToast("Prima di timbrare devi selezionare il reparto di riferimento", "toast_danger");
                return;
            }
            //Se devo comunicare le coordinate
            if (dipendenteSettings.value.dipendenti_geolocalizzazione === "1" && !position.value) {
                openToast("Prima di timbrare devi permettere la geolocalizzazione", "toast_danger");
                return;
            }

            const data_ordierna = moment().format("YYYY-MM-DD");
            const ora_entrata = moment().format("HH:mm");
            //Se nel mio profilo c'è un orario di inizio e fine per la giornata di oggi salva l'entrata altrimenti errore
            timbraEntrata(data_ordierna, ora_entrata, repartoSelezionato.value);
        }

        /* ===================================================================================================================== */
        //1) SCARICO TIMBRATURE EFFETTUATE e controllo se disabilitare entrata
        onMounted(() => {
            loadTimbrature();
        });

        /* Ad ogni accesso alla pagina controllo se disabilitare il pulsante */
        onIonViewWillEnter(() => {
            //Richiesta ai dati del dipendente per abilitare/disabilitare button
            getDipendenteData();
            loadTimbrature();
        });

        onBeforeUnmount(() => {
            clearInterval(timer.value);
        });

        return {
            loading,
            dateFormat,
            timeFormat,
            refresh,
            richieste,
            loadTimbrature,
            //Presenze
            presenze,
            handleTimbraEntrata,
            handleTimbraUscita,
            entrataTimbrata,
            uscitaTimbrata,
            //Reperibilità
            statoReperibilita,
            statusShowItem,
            //setStraordinario,
            //reperibilita,
            menu,
            openMenu,
            time,
            arrowBack,
            arrowForward,
            //Pagina reperibilita
            openReperibilita,
            //Straordinario
            apriStraordinario,
            chiudiStraordinario,
            selectedPresenza,
            showStraordinario,
            //salvaStraordinario,
            editPresenza,
            todayDate,
            //QR code data,
            openQrCode,
            showQrCode,
            QrStream,
            ...toRefs(state),
            onDecode,
            chiudiQrCode,
            handleQrCodeTimbratura,
            confirmationText,
            disabledQrCodeButton,
            //Creazione presenza completa
            createPresenza,
            printCustomer,
            //Selezione reparto per effettuare la presenza
            repartoSelezionato,
            reparti,
            setReparto,
            //MOSTRA / NASCONDI BUTTON IN BASE AI SETTINGS DEL DIPENDENTE
            dipendenteSettings,
            deletePresenza,
        };
    },
};
