<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title color="white">Reperibilità</ion-title>
        <ion-buttons slot="start">
          <ion-button @click="closeModal()">
            <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button @click="loadReperibilita()">
            <ion-icon slot="start" :icon="refresh" color="white"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <div class="page_content">
        <div class="datepicker_container">
          <Calendar v-model="pickerDate" :value="pickerDate" is-expanded :attributes="setAttributes" @dayclick="dayClicked" class="calendar_container">
          </Calendar>

          <!-- <div class="info">Verrà comunicata la reperibilità per la data {{ dateFormat(pickerDate) }}</div> -->
          <div class="info">Sarai reperibile in data {{ dateFormat(pickerDate) }}</div>

          <div class="actions">
            <button @click="saveReperibilita" class="btn_save_reperibilita">
              Comunica reperibilità
            </button>
          </div>

          <div v-if="loading" class="loading_spinner">
            <ion-spinner name="crescent" color="dark"></ion-spinner>
          </div>
          <div v-else class="reperibilita_container">
            <div v-for="rep in reperibilita" :key="rep.reperibilita_id" class="reperibilita">
              <div class="date">
                {{ dateFormat(rep.reperibilita_data) }}
              </div>
              <!-- <div v-show="canDelete(rep)" class="action">
                <ion-icon :icon="trash" @click="deleteReperibilita(rep.reperibilita_id, rep)"></ion-icon>
              </div> -->
              <div class="action">
                <ion-icon :icon="trash" @click="deleteReperibilita(rep.reperibilita_id)"></ion-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  //IonBackButton,
  IonTitle,
  IonContent,
  IonSpinner,
  IonIcon,
  IonButton,
  modalController,
} from "@ionic/vue";

import { defineComponent, onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import { arrowBackOutline, refresh, trash } from "ionicons/icons";

import moment from "moment";
import { dateFormat } from "../services/utils";
import { openToast } from "../services/toast";

import { Calendar } from "v-calendar";

import apiReperibilita from "../services/reperibilita";

export default defineComponent({
  name: "ReperibilitaModal",
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonSpinner,
    IonIcon,
    IonButton,
    Calendar,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const dipendenteID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_id;

    const loading = ref(false);

    const pickerDate = ref(new Date());

    const reperibilita = ref([]);

    /**
     * Handle close modal by clicking on Chiudi button
     */
    const closeModal = () => {
      modalController.dismiss();
    };

    /**
     * ! Se la reperibilità è stata inserita entro un ora da adesso lascio la possivilità di cancellarla
     */
    function canDelete(rep) {
      const now = moment();
      const creation = moment(rep.reperibilita_creation_date);
      const isInRange = moment(creation).isAfter(moment(now).subtract(1, "hours"));

      if (isInRange) {
        return true;
      }
      return false;
    }

    /**
     * Set calendar attribute
     */
    const setAttributes = computed(() => {
      return reperibilita.value.map((rep) => ({
        key: `ticket.${rep.reperibilita_id}`,
        highlight: {
          color: "blue",
          fillMode: "solid",
        },
        dates: rep.reperibilita_data,
        customData: rep,
      }));
    });

    /**
     * Set selected day on calendar click
     */
    const dayClicked = (day) => {
      /*       console.log(day.attributes);
      console.log(day); */
      pickerDate.value = day.date;
    };

    /**
     * ! Crea reperbilità per la giornata selezionata
     */
    function saveReperibilita() {
      const today = new Date();
      if (moment(pickerDate.value).format("YYYY-MM-DD") < moment(today).format("YYYY-MM-DD")) {
        openToast("Non puoi comunicare la reperibilità per una data passata", "toast_danger");
        return;
      }

      let selectedDate = null;

      if (pickerDate.value && moment.isDate(new Date(pickerDate.value))) {
        selectedDate = moment(pickerDate.value).format("YYYY-MM-DD");

        apiReperibilita
          .setReperibilita(dipendenteID, selectedDate)
          .then((response) => {
            console.log(response);
            if (response.data.status === 0) {
              openToast(response.data.txt, "toast_danger");
            } else if (response.data.status === 1) {
              openToast(response.data.txt, "toast_success");
              // resetta data selezioanta ad oggi e aggiugne reperibilita creata all'array
              pickerDate.value = new Date();
              reperibilita.value = [response.data.data, ...reperibilita.value];
            }
          })
          .catch((error) => {
            console.error(error);
            openToast("Errore durante il salvataggio della reperibilità", "toast_danger");
          });
      } else {
        openToast("Devi selezionare una data per creare la reperibilità", "toast_danger");
      }
    }

    /**
     * ! Salva reperibilità per la data scelta
     */
    /*     function saveReperibilita() {
      const today = new Date();
      if (moment(pickerDate.value).format("YYYY-MM-DD") < moment(today).format("YYYY-MM-DD")) {
        openToast("Non puoi comunicare la reperibilità per una data passata", "toast_danger");
        return;
      }

      let selectedDate = null;
      if (pickerDate.value && moment.isDate(new Date(pickerDate.value))) {
        selectedDate = moment(pickerDate.value).format("YYYY-MM-DD");
      }
      reperibilita(selectedDate);
    } */

    async function deleteReperibilita(reperibilita_id) {
      try {
        const res = await apiReperibilita.deleteReperibilita(reperibilita_id);

        if (res.status === 0) {
          openToast(res.txt, "toast_danger");
        } else {
          reperibilita.value = reperibilita.value.filter((rep) => rep.reperibilita_id != res.data.data.reperibilita_id);
          openToast(res.txt, "toast_success");
        }
      } catch (error) {
        console.error(error);
        openToast("Errore durante la richiesta delle reperibilità", "toast_danger");
      }
    }

    /**
     * ! Load all reperibilita for the logged user
     */
    async function loadReperibilita() {
      loading.value = true;
      try {
        const res = await apiReperibilita.getReperibilita(dipendenteID);

        if (res.status === 0) {
          reperibilita.value = res.data;
        } else {
          openToast("Errore durante la richiesta delle reperibilità", "toast_danger");
        }
      } catch (error) {
        console.error(error);
        openToast("Errore durante la richiesta delle reperibilità", "toast_danger");
      } finally {
        loading.value = false;
      }
    }

    onMounted(() => {
      loadReperibilita();
    });

    return {
      arrowBackOutline,
      trash,
      refresh,

      loading,
      dateFormat,
      closeModal,
      //DatePicker
      pickerDate,

      loadReperibilita,
      saveReperibilita,
      reperibilita,
      deleteReperibilita,
      canDelete,
      dayClicked,
      setAttributes,
    };
  },
});
</script>

<style scoped>
ion-toolbar {
  --background: #086fa3;
  --color: #ffffff;
}
.loading_spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 32px;
}

/* New rules */
.page_content {
  width: 100%;
  position: relative; /* to be used for new richiesta btn */
  padding: 16px;
  min-height: 100%;
  background: #f8fafc;
}

/* Datepicker */
.datepicker_container {
}
.datepicker_container .info {
  color: #475569;
  margin: 16px 0;
}
.datepicker_container .btn_save_reperibilita {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 20px;
  margin-bottom: 8px;
  box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.datepicker_container .btn_save_reperibilita {
  background-color: rgb(22 163 74);
  color: #ffffff;
  transition: all 0.15s ease-in;
}
/* .datepicker_container .btn_save_reperibilita:active {
  background-color: rgb(134 239 172);
  color: rgb(22 163 74);
} */

.reperibilita_container {
  width: 100%;
  margin-top: 16px;
}
.reperibilita {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.reperibilita .date {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
}

.reperibilita .action {
  display: flex;
  justify-content: center;
  background-color: rgb(254 226 226);
  color: rgb(239 68 68);
  font-size: 14px;
  border-radius: 4px;
  padding: 4px;
}

ion-button {
  --color: #ffffff;
}
</style>
